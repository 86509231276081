<template>
    <a-form
        class="login-form"
        :form="form"
        @submit="handleSubmit">
        <a-alert
            v-if="errorMsg !== '' && (typeof errorMsg !== undefined)"
            class="login-form__error-msg"
            type="error"
            :message="errorMsg"
            banner="banner" />

        <a-form-item>
            <a-input
                v-decorator="[
                    'username',
                    { rules: [ { required: true, message: 'กรุณาใส่ ชื่อผู้ใช้' } ] } ]"
                placeholder="ชื่อผู้ใช้">
                <a-icon
                    slot="prefix"
                    type="user" />
            </a-input>
        </a-form-item>

        <a-form-item>
            <a-input
                v-decorator="[
                    'password',
                    { rules: [ { required: true, message: 'กรุณาใส่ รหัสผ่าน' } ] } ]"
                type="password"
                placeholder="รหัสผ่าน">
                <a-icon
                    slot="prefix"
                    type="lock" />
            </a-input>
        </a-form-item>

        <a-form-item>

            <a class="login-form__forgot" href="/forgot-password">ลืมรหัสผ่าน</a>

            <a-button
                class="login-form__button"
                type="primary"
                html-type="submit"
                :loading="isProcessing"
                icon="login"
                block>
                เข้าสู่ระบบ
            </a-button>

            หรือ

            <a href="https://lenkila.com/aboutus/">เปิดบัญชีผู้ใช้ใหม่!</a>
        </a-form-item>
    </a-form>
</template>

<script>
import { rootActions } from '@/store/types'

export default {
    data () {
        return {
            errorMsg: '',
            isProcessing: false,
        }
    },
    beforeCreate () {
        this.form = this.$form.createForm(this)
    },
    methods: {
        handleSubmit (e) {
            e.preventDefault()
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    this.isProcessing = true
                    const res = await this.$store.dispatch(rootActions.LOGIN, values)
                    if (res.isSuccess()) {
                        if (this.$route.query && this.$route.query.redirect) {
                            this.$router.replace(this.$route.query.redirect).catch(() => {
                                // Empty catch because we manage errors on router.js
                            })
                        } else {
                            this.$router.replace('/').catch(() => {
                                // Empty catch because we manage errors on router.js
                            })
                        }
                    } else {
                        this.errorMsg = res.msg
                    }
                    this.isProcessing = false
                }
            })
        },
    },
}
</script>

<style lang="stylus">
.login-form
    max-width: 700px
    display: inline-block
    text-align: left

.login-form__forgot
    float: right

.login-form__error-msg
    margin-bottom: 16px
</style>
