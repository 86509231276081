<template>
    <VLayoutMiddle>
        <div class="login__form">
            <a-row type="flex" align="middle">
                <a-col
                    :xs="{ span: 24 }"
                    :xl="{ span: 10, offset: 3 }"
                    :xxl="{ span: 7, offset: 5 }">
                    <img
                        class="login__logo"
                        alt="Lenkila Stadium logo"
                        src="@/assets/Logo.png">
                </a-col>
                <a-col
                    :xs="{ span: 24 }"
                    :xl="{ span: 5, offset: 1 }"
                    :xxl="{ span: 4, offset: 0 }">
                    <LoginForm />
                </a-col>
            </a-row>
        </div>
    </VLayoutMiddle>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'
import VLayoutMiddle from '@/components/VLayoutMiddle.vue'
import { rootActions } from '@/store/types'

export default {
    components: {
        LoginForm,
        VLayoutMiddle,
    },
    mounted () {
        this.$store.dispatch(rootActions.RESET_STORES)
    },
}
</script>

<style lang="stylus">
.login__logo
    width: 100%
    max-width: 640px
    margin-bottom: 48px
    // margin-right: 320px
    // vertical-align: baseline
.login__form
    text-align: center
    margin: 0 auto
    // padding-right: 12%
</style>
