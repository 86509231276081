var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VLayoutMiddle", [
    _c(
      "div",
      { staticClass: "login__form" },
      [
        _c(
          "a-row",
          { attrs: { type: "flex", align: "middle" } },
          [
            _c(
              "a-col",
              {
                attrs: {
                  xs: { span: 24 },
                  xl: { span: 10, offset: 3 },
                  xxl: { span: 7, offset: 5 }
                }
              },
              [
                _c("img", {
                  staticClass: "login__logo",
                  attrs: {
                    alt: "Lenkila Stadium logo",
                    src: require("@/assets/Logo.png")
                  }
                })
              ]
            ),
            _c(
              "a-col",
              {
                attrs: {
                  xs: { span: 24 },
                  xl: { span: 5, offset: 1 },
                  xxl: { span: 4, offset: 0 }
                }
              },
              [_c("LoginForm")],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }