var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "login-form",
      attrs: { form: _vm.form },
      on: { submit: _vm.handleSubmit }
    },
    [
      _vm.errorMsg !== "" && typeof _vm.errorMsg !== undefined
        ? _c("a-alert", {
            staticClass: "login-form__error-msg",
            attrs: { type: "error", message: _vm.errorMsg, banner: "banner" }
          })
        : _vm._e(),
      _c(
        "a-form-item",
        [
          _c(
            "a-input",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "username",
                    {
                      rules: [
                        { required: true, message: "กรุณาใส่ ชื่อผู้ใช้" }
                      ]
                    }
                  ],
                  expression:
                    "[\n                'username',\n                { rules: [ { required: true, message: 'กรุณาใส่ ชื่อผู้ใช้' } ] } ]"
                }
              ],
              attrs: { placeholder: "ชื่อผู้ใช้" }
            },
            [
              _c("a-icon", {
                attrs: { slot: "prefix", type: "user" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        [
          _c(
            "a-input",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "password",
                    {
                      rules: [{ required: true, message: "กรุณาใส่ รหัสผ่าน" }]
                    }
                  ],
                  expression:
                    "[\n                'password',\n                { rules: [ { required: true, message: 'กรุณาใส่ รหัสผ่าน' } ] } ]"
                }
              ],
              attrs: { type: "password", placeholder: "รหัสผ่าน" }
            },
            [
              _c("a-icon", {
                attrs: { slot: "prefix", type: "lock" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        [
          _c(
            "a",
            {
              staticClass: "login-form__forgot",
              attrs: { href: "/forgot-password" }
            },
            [_vm._v("ลืมรหัสผ่าน")]
          ),
          _c(
            "a-button",
            {
              staticClass: "login-form__button",
              attrs: {
                type: "primary",
                "html-type": "submit",
                loading: _vm.isProcessing,
                icon: "login",
                block: ""
              }
            },
            [_vm._v("\n            เข้าสู่ระบบ\n        ")]
          ),
          _vm._v("\n\n        หรือ\n\n        "),
          _c("a", { attrs: { href: "https://lenkila.com/aboutus/" } }, [
            _vm._v("เปิดบัญชีผู้ใช้ใหม่!")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }